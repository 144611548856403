import { Box, Flex } from '@mantine/core'

import { Typography } from '~/components/shared/text/Typography'

import { LocalImage } from '../shared/image/LocalImage'

import heroImg from 'public/images/home/thatch-hero-image.png'

import { Title, TitleItalic } from '~/components/shared/text/Title'
import { SearchBox } from './SearchBox'

const HeroSection = ({
  isMobileScreen,
}: {
  isMobileScreen: boolean
}) => {
  return (
    <Flex
      justify="space-between"
      direction={isMobileScreen ? 'column' : 'row'}
      mb={isMobileScreen ? 0 : 40}
      gap={isMobileScreen ? 56 : undefined}
      w="100%"
    >
      <Flex
        direction="column"
        justify="center"
        gap={isMobileScreen ? 32 : 48}
      >
        <Title variant='h1' order={1} className='text-center md:text-left text-[58px] md:text-[80px]'>Great trips<br /><TitleItalic>start here</TitleItalic></Title>

        <Flex
          direction="column"
          gap={16}
          maw={511}
        >
          <Typography
            variant="body1"
            sx={{ letterSpacing: '-0.088px', textAlign: isMobileScreen ? 'center' : undefined }}
            maw={450}
          >
            Discover unique travel guides and planning services by local experts in 80+ countries.
          </Typography>
        </Flex>
        <div className='block md:hidden'>
          <SearchBox placeholder='Explore Thatch' source='homepage_hero'/>
        </div>
      </Flex>

      <Box ml={isMobileScreen ? -16 : 0}>
        <LocalImage
          src={heroImg}
          width={isMobileScreen ? window.innerWidth : 511}
          priority
          quality={100}
          alt="hero"
        />
      </Box>
    </Flex>
  )
}

export default HeroSection
