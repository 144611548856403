import {
    Flex,
    Group,
    Image
} from '@mantine/core'
import { useScreenSize } from '~/hooks'

import { Typography } from '../shared/text/Typography'

import adventureImg from 'public/images/geo/adventure.png'
import roadtripImg from 'public/images/geo/roadtrip.png'
import wellnessImg from 'public/images/geo/wellness.png'

const InfoCard = ({
    title,
    imgUrl,
    isMobileScreen,
}: {
    title: string
    imgUrl: string
    isMobileScreen: boolean
}) => (
    <Flex
        py={48}
        px={32}
        gap={32}
        maw={isMobileScreen ? '100%' : 264}
        w="100%"
        align="center"
        direction="column"
        bg="appPaper.0"
        sx={{ borderRadius: 8 }}
    >
        <Image src={imgUrl} width={120} height={120} alt="Illustration"/>
        <Typography
            variant={isMobileScreen ? 'title' : 'body1'}
            align="center"
            w={168}
        >
            {title}
        </Typography>
    </Flex>
)

export function FeatureCards() {
    const { isMobileScreen } = useScreenSize()
    return (
        <Flex
            gap={16}
            direction={isMobileScreen ? 'column' : 'row'}
        >
            <Flex
                p={32}
                gap={32}
                maw={isMobileScreen ? '100%' : 264}
                w="100%"
                sx={{ borderRadius: 8, letterSpacing: '-0.216px' }}
                bg={isMobileScreen ? 'appPaper.0' : 'appMint.0'}
                direction="column"
                align="center"
                justify="center"
            >
                <Group
                    sx={isMobileScreen ? { justifyContent: 'center' } : {}}
                    w={isMobileScreen ? 250 : 143}
                    spacing={isMobileScreen ? 5 : 0}
                >
                    <Typography variant={isMobileScreen ? 'h2' : 'h3'}>Happy travelers</Typography>
                    <Typography variant={isMobileScreen ? 'h2Italic' : 'h3Italic'}>Thatch</Typography>
                    <Typography variant={isMobileScreen ? 'h2Italic' : 'h3'}>their trips</Typography>
                </Group>
            </Flex>

            <InfoCard
                title="Reduce planning time and stress"
                imgUrl={wellnessImg.src}
                isMobileScreen={isMobileScreen}
            />
            <InfoCard
                title="Discover hidden gems & local spots"
                imgUrl={adventureImg.src}
                isMobileScreen={isMobileScreen}
            />
            <InfoCard
                title="Take trips that are true to you"
                imgUrl={roadtripImg.src}
                isMobileScreen={isMobileScreen}
            />
        </Flex>
    )
}