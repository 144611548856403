import { Box, Divider, Flex, Sx } from '@mantine/core'
import Link from 'next/link'
import React, { useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

// import { ExploreEventsEnum } from '~/analytics/ExploreEvent'
import { EmptyState } from '~/components/explore/EmptyState'
import { Typography } from '~/components/shared/text/Typography'
import { useAnalytics } from '~/context'
import { BoardSummaryType } from '~/endpoints/model'
import { useScreenSize } from '~/hooks/useMobileScreen'

import {
  useCreateDuplicate,
  useDelete,
  useToggleFavUnFav,
  useToggleSaveUnSave,
} from '../board/summary/menu/hooks'
import { GuideInfoCard } from '../guide/GuideInfoCard'
import { ThatchButton } from '../shared/ThatchButton'
import { GuideCardPlaceholder } from './GuideCardPlaceholder'

export interface GuidesContentProps {
  data: BoardSummaryType[]
  onFetchData?: () => void
  isLoading?: boolean
  hasMore?: boolean
  wrapperStyles?: Sx
  onGuideUpdate?: (board: BoardSummaryType) => Promise<void> | void
  onGuidesUpdate?: (board: BoardSummaryType[]) => Promise<void> | void
  emptyState?: any
  showAllGuidesBtn?: boolean
  loadingContainerSx?: Sx
  showMenu?: boolean
}
export const GuidesContent: React.FC<GuidesContentProps> = ({
  data,
  emptyState,
  hasMore,
  onFetchData,
  isLoading,
  wrapperStyles = {},
  showMenu,
  onGuidesUpdate,
  showAllGuidesBtn,
  loadingContainerSx,
}) => {
  const thatchSegment = useAnalytics()
  const { isSmallScreen } = useScreenSize()
  const [isLoadingGuideToken, setLoadingGuideToken] = useState<string | null>(null)

  const wrapperStyle = {
    maxWidth: 1400,
    margin: '0 auto',
    padding: `0 ${isSmallScreen ? 16 : 40}px`,
    ...wrapperStyles,
  }

  const onNext = async () => {
    onFetchData?.()
  }

  const findBoard = (token: string) => {
    const idx = data.findIndex(e => e.token == token)
    if (idx > -1) {
      return { board: data[idx], idx: idx }
    }
    return { board: null, idx: -1 }
  }

  // Delete menu action
  const onDeleteComplete = (idx: number) => {
    onGuidesUpdate?.(data.filter((_, index) => index !== idx))
  }

  const { deleteGuide } = useDelete(onDeleteComplete)
  const handleDelete = async (token: string) => {
    setLoadingGuideToken(token)
    const { board, idx } = findBoard(token)
    if (idx > -1 && board) {
      await deleteGuide(board, idx)
    }
    setLoadingGuideToken(null)
  }

  // Fav UnFav Menu Action
  const onFavUnFavComplete = (idx: number, isFav: boolean) => {
    const changedBoard = data[idx]
    changedBoard.state.pinned = isFav
    const newList = [...data]
    onGuidesUpdate?.(newList)
  }
  const { toggleFavUnFav } = useToggleFavUnFav(onFavUnFavComplete)
  const handleToggleFav = async (token: string) => {
    setLoadingGuideToken(token)
    const { board, idx } = findBoard(token)
    if (idx > -1 && board) {
      await toggleFavUnFav(board, idx)
    }
    setLoadingGuideToken(null)
  }

  const onSaveUnSaveComplete = (idx: number, isSaved: boolean) => {
    const changedBoard = data[idx]
    changedBoard.state.saved = isSaved
    changedBoard.saveCount += isSaved ? 1 : -1
    const newList = [...data]
    onGuidesUpdate?.(newList)
  }
  const { toggleSaveUnSave } = useToggleSaveUnSave(onSaveUnSaveComplete)
  const handleUnSave = async (token: string) => {
    setLoadingGuideToken(token)
    const { board, idx } = findBoard(token)
    if (idx > -1 && board) {
      await toggleSaveUnSave(board, idx)
    }
    setLoadingGuideToken(null)
  }

  // Duplicate Menu Action
  const onDuplicateComplete = (newBoard: BoardSummaryType) => {
    const newList = [newBoard, ...data]
    onGuidesUpdate?.(newList)
  }
  const { duplicateGuide } = useCreateDuplicate(onDuplicateComplete)
  const handleDuplicate = async (token: string) => {
    setLoadingGuideToken(token)
    const { board, idx } = findBoard(token)
    if (idx > -1 && board) {
      await duplicateGuide(board)
    }
    setLoadingGuideToken(null)
  }

  // Force re-render when contents are updated.
  // Need this since InfiniteScroll component keeps its scroll position
  // and we have no way to reset it's scroll position other than re-rendering it.
  const renderInfiniteScroll = useMemo(() => {
    return (
      <InfiniteScroll
        dataLength={data.length}
        style={{ overflowX: 'hidden' }}
        className="explore-infinite-scroll"
        next={onNext}
        hasMore={!!hasMore}
        loader={
          <Typography
            sx={wrapperStyle}
            align="center"
            mb={16}
          >
            Loading more guides...
          </Typography>
        }
      >
        <Flex
          direction="column"
          align={isSmallScreen ? 'center' : 'start'}
          sx={{ overflow: 'hidden', ...wrapperStyle }}
        >
          {data.map((item, idx) => (
            <GuideInfoCard
              key={`guide-${idx}-${item.token}`}
              data={item}
              isSignedIn={true}
              onDelete={handleDelete}
              onDuplicate={handleDuplicate}
              onToggleFav={handleToggleFav}
              onUnsave={handleUnSave}
              showMenu={showMenu}
              isLoading={isLoadingGuideToken === item.token}
              trackingSource="serp"
              onModalClose={() => {}}
              onClickAnalyticsCallback={(boardSummary: BoardSummaryType) => {
                // thatchSegment.trackExploreEvent(ExploreEventsEnum.enum.ResultClicked, {
                //   //TODO: fix this query name
                //   //searchQuery: props.searchTerm,
                //   boardToken: boardSummary.token,
                //   boardPrice: boardSummary.price,
                // })
              }}
            />
          ))}
          {showAllGuidesBtn && (
            <ThatchButton
              label={<Link href="/guides/all?page=1">All Guides & Itineraries →</Link>}
              size="medium"
              sx={{
                width: 'min-content',
                marginTop: 32,
                marginBottom: 2,
              }}
              variant="outline"
            />
          )}
        </Flex>
      </InfiniteScroll>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, hasMore, isLoading])

  return isLoading ? (
    <Flex
      gap={32}
      px={isSmallScreen ? 16 : 40}
      pt={32}
      pb={32}
      direction="column"
      w="100%"
      sx={loadingContainerSx}
    >
      {Array.from(Array(12)).map((item, index) => (
        <Box
          key={`seller-${index}`}
          w="100%"
        >
          <GuideCardPlaceholder />
          {index < 12 && <Divider mt={32} />}
        </Box>
      ))}
    </Flex>
  ) : data.length > 0 ? (
    renderInfiniteScroll
  ) : emptyState !== undefined ? (
    emptyState
  ) : (
    <Box sx={wrapperStyle}>
      <EmptyState />
    </Box>
  )
}
